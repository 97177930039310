import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef, ComponentRef } from '@angular/core';
import { IndividualChatWindowComponent } from '../individual-chat-window/individual-chat-window.component';
import { IndividualChatmodel } from 'src/app/dto/IndividualChatmodel';


@Injectable({
    providedIn: 'root'
})
export class DynamicComponentService {
    private chatComponents: ComponentRef<IndividualChatWindowComponent>[] = [];
    private readonly MAX_CHATS = 4;
    private readonly CHAT_WIDTH = 300; // px
    private readonly CHAT_MARGIN = 10; // px

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) { }

    createChatWindow(userData: IndividualChatmodel): void {
        // Check if a chat window for the given user already exists
        if (this.chatComponents.some(comp => comp.instance.userData.RecipientId === userData.RecipientId)) {
            console.warn('Chat window for this user already exists');
            return;
        }

        if (this.chatComponents.length >= this.MAX_CHATS) {
            console.warn('Maximum number of chat windows reached');
            return;
        }

        const componentRef = this.componentFactoryResolver
            .resolveComponentFactory(IndividualChatWindowComponent)
            .create(this.injector);

        const domElem = (componentRef.hostView as EmbeddedViewRef<IndividualChatmodel>).rootNodes[0] as HTMLElement;
        domElem.style.transform = 'translateX(100%)';

        if (domElem.shadowRoot) {
            const style = document.createElement('style');
            style.textContent = `              
              @import url('/assets/primeicons/primeicons.css');
              .pi {
                font-family: 'PrimeIcons';
              }              
            `;
            domElem.shadowRoot.appendChild(style);
        }

        document.body.appendChild(domElem);

        componentRef.instance.userData = { ...userData };
        componentRef.instance.messages = [];
        componentRef.instance.close.subscribe(() => this.removeChatWindow(componentRef));

        this.chatComponents.push(componentRef);

        this.positionChatWindows();

        this.appRef.attachView(componentRef.hostView);

        // Trigger slide-in animation
        setTimeout(() => {
            domElem.style.transform = 'translateX(0)';
        }, 50);
    }

    private removeChatWindow(componentRef: ComponentRef<IndividualChatWindowComponent>): void {
        const index = this.chatComponents.indexOf(componentRef);
        if (index > -1) {
            this.chatComponents.splice(index, 1);
            this.appRef.detachView(componentRef.hostView);
            componentRef.destroy();
            this.positionChatWindows();
        }
    }

    private positionChatWindows(): void {
        const screenWidth = window.innerWidth;
        const totalChatWindows = this.chatComponents.length;

        // Calculate how many windows can fit on screen
        const maxWindows = Math.min(
            Math.floor((screenWidth - this.CHAT_MARGIN) / (this.CHAT_WIDTH + this.CHAT_MARGIN)),
            this.MAX_CHATS
        );

        // Position windows from right to left
        this.chatComponents.forEach((comp, index) => {
            const elem = (comp.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

            // Calculate right position
            const rightPosition = index * (this.CHAT_WIDTH + this.CHAT_MARGIN);

            // Apply styles
            elem.style.right = `${rightPosition}px`;
            elem.style.bottom = '0px';
            elem.style.position = 'fixed'; // Ensure fixed positioning
            elem.style.zIndex = `${1000 + index}`;

            // Ensure visibility
            elem.style.transform = 'translateX(0)';
            elem.style.transition = 'transform 0.3s ease-in-out';
        });
    }
}