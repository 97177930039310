import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { GlobalLoaderService } from 'src/app/common/reusable/servicecenter/global-loader-service';
import { PrefetchBackGroundService } from 'src/app/common/reusable/servicecenter/PrefetchBackGroundService';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { UserSavedSearchResult } from "src/app/dto/UserSavedSearchResult";

@Component({
  selector: 'home-saved-searches',
  templateUrl: './home-saved-searches.component.html',
  styleUrls: ['./home-saved-searches.component.css'],
  providers: [MessageService]
})
export class HomeSavedSearchesComponent implements OnInit {
  savedSearches: Array<UserSavedSearchResult>;
  userloggedIn: string;
  showLargeImage = false;
  userId: string;
  router: Router;
  imageToDisplay: HostWindowModel;
  showLoader = true;

  constructor(private commonService: Commonservice, _router: Router, private localStore: LocalStore, private confirmationService: ConfirmationService, private messageService: MessageService, private loaderService: GlobalLoaderService) { this.router = _router; }
  ngOnInit() {
    const self = this;
    this.loaderService.loader$.subscribe(loader => { this.showLoader = loader; });
    self.showLargeImage = true;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserName;
    self.userloggedIn = self.userloggedIn;
    self.savedSearches = [];
    self.bindGrid(); 
  }


  bindGrid(){
    const self = this; 
    this.loaderService.showLoader();  
    self.commonService.getSavedSearches(self.userloggedIn).subscribe(
      data => {
        self.savedSearches = data;
        this.loaderService.hideLoader();
      },
      error => {        

      }
    );
  }

  navigateToSearch(item: UserSavedSearchResult) {
    const self = this;
    switch (item.AdvancedOrQuick) {
      case "1":
        sessionStorage.setItem("QuickSearch", item.QuickSearchParam);
        self.router.navigateByUrl("/quicksearch");
        break;
      case "2":
        sessionStorage.setItem("AdvancedSearch", item.QuickSearchParam);
        self.router.navigateByUrl("/advancedsearch");
        break;
    }
  }

  removeSearch(item: UserSavedSearchResult, event: Event) {
    const self = this;
    event.stopPropagation();
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are You sure You want to delete the saved search?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        self.commonService.deleteSavedSearch(item.id).subscribe(dataMain => {
          if (dataMain.DeleteWorked) {
            self.bindGrid();
            this.messageService.add({ severity: 'success', summary: 'Service Message', detail: dataMain.Message });
          }
          else {
            this.messageService.add({ severity: 'error', summary: 'Service Message', detail: dataMain.Message });
          }
        });
      },
      reject: () => {
        //reject action
      }
    });
  }
}
