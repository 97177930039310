<super-floating-loader [isLoading]="showLoader" [backgroundColor]="'rgba(0, 0, 0, 0.07)'"></super-floating-loader>
<ng-containter *ngIf="!showLoader">
  <div class="my-horizontal-list">
    <div>
      <div class="table-responsive">
        <table class="table" *ngIf="savedSearches?.length > 0">
          <tr>
            <td>
              <div class="my-horizontal-list">
                <div *ngFor="
                    let item of savedSearches
                      | paginate
                        : {
                            itemsPerPage: 10,
                            currentPage: savedSearchPageNumber,
                            id: 'SavedSearches'
                          }
                  " style="margin-right: 5px">
                  <button class="hyperlink-In-button" (click)="navigateToSearch(item)">
                    {{ item.searchName }}
                    <span class="close-btn" (click)="removeSearch(item, $event)">×</span> 
                  </button>
                 
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>

            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>

  <div style="margin-top: 10px; text-align: center" *ngIf="savedSearches?.length>0">
    <pagination-controls (pageChange)="savedSearchPageNumber = $event" id="SavedSearches">
    </pagination-controls>
  </div>

  <div *ngIf="savedSearches?.length === 0" class="no-value-text-saved">
    No saved searches yet
  </div>
</ng-containter>

<p-confirmPopup></p-confirmPopup>
<p-toast position="bottom-right"></p-toast>