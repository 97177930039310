<ng-container [ngSwitch]="currentDeviceState">

    <ng-container *ngSwitchCase="'desktop-landscape'">
        <ng-container *ngTemplateOutlet="desktopLandscape"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'mobile-portrait'">
        <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'tablet-portrait'">
        <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
    </ng-container>

</ng-container>

<div class="image-box">

    <div class="image-case">
        <img src="../../../assets/image/mc/mc1.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc2.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc3.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc4.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc5.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc6.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc7.webp" alt="" class="image-being-displayed-in-box">
    </div>

    <div class="image-case">
        <img src="../../../assets/image/mc/mc8.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc9.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc10.webp" alt="" class="image-being-displayed-in-box">
    </div>
</div>
<ng-template #featureCellTemplate let-text="text" let-iconClass="iconClass">
    <div class="feature-cellls">
        <div class="text-field">{{ text }}</div>
        <div class="icon-field"><i class="{{ iconClass }} icon-style"></i></div>
    </div>
</ng-template>


<ng-template #mobilePortrait>
    <div class="premium-offer">
        <div class="membership-offers">
            Premium Membership offers
        </div>
        <ul class="custom-ul">
            <li class="custom-li">Express Interest</li>
            <li class="custom-li">Send and receive personalized messages</li>
            <li class="custom-li">Store up to 7 images</li>
            <li class="custom-li">Chat with online members</li>
            <li class="custom-li">Bookmark selected prospects</li>
            <li class="custom-li">View full profiles of your prospects</li>
            <li class="custom-li">Contact Details (Verified & Unvarified)(Count is based on plan)</li>
            <li class="custom-li">Mobile friendly views</li>
            <li class="custom-li">Save Searches</li>
            <li class="custom-li">Blazing fast searches</li>
            <li class="custom-li">Fast image loads</li>
            <li class="custom-li">Comfirmation mail on Interests Acceptance</li>
        </ul>
    </div>
</ng-template>

<ng-template #desktopLandscape>
    <div class="flyer">

        <div class="membership-offers">
            Premium Membership offers
        </div>
        <ul>
            <li>Express Interest</li>
            <li>Send and receive personalized messages</li>
            <li>Store up to 7 images</li>
            <li>Chat with online members</li>
        </ul>
        <ul>
            <li>Bookmark selected prospects</li>
            <li>View full profiles of your prospects</li>
            <li>Contact Details (Verified & Unvarified)(Count is based on plan)</li>
            <li>Mobile friendly views</li>
        </ul>
        <ul>
            <li>Save Searches</li>
            <li>Blazing fast searches</li>
            <li>Fast image loads</li>
            <li>Comfirmation mail on Interests Acceptance</li>
        </ul>
    </div>
</ng-template>