import { Component, OnInit } from '@angular/core';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';

@Component({
  selector: 'loginhomeoffers',
  templateUrl: './loginhomeoffers.component.html',
  styleUrls: ['./loginhomeoffers.component.css']
})
export class LoginhomeoffersComponent implements OnInit {
  currentDeviceState: string = "";
  currentClass: string;
  constructor( private signalStateService: SignalStateService) { }

  ngOnInit() {
    this.currentDeviceState = this.signalStateService.getValue(APP_CONSTANTS.ResolutionData)();  
  }

  setScreenClass(screenType: string) {
    switch (screenType) {
      case "desktop-landscape":
        this.currentClass = 'image';
        break;
      case "mobile-portrait":
        this.currentClass = 'image-mobile';
        break;
      case "tablet-portrait":
        this.currentClass = 'image-mobile';
        break;
      default:
        this.currentClass = 'image';
        break;
    }
  }
}
