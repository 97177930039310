<super-floating-loader [isLoading]="showLoader" [backgroundColor]="'rgba(0, 0, 0, 0.07)'"></super-floating-loader>
<ng-containter *ngIf="!showLoader">
  <p-fieldset legend="MY RECENT VISITS">
    <div *ngIf="yourRecentVisitsOVC?.length > 0" class="vertical-list">


      <div class="card-container">
        <p-card *ngFor="let item of yourRecentVisitsOVC
      | paginate
        : {
            itemsPerPage: 5,
            currentPage: YourRecentVisitsOVCNumber,
            id: 'YourRecentVisitsOVC'
          }" class="card">
          <ng-template pTemplate="header">
            <img [src]="item.ProfileImageIdOfCandidateSrc" [ngClass]="[
          item.ProfileImageIdOfCandidateSrc
            ? 'bookmark-image img-thumbnail'
            : 'bookmark-image-no-cursor'
        ]" (click)="
          makeitbig(                  
            item.ProfileImageIdOfCandidateOVC,
            item.IdOfPersonIVisitedOVC
          )
        " />
          </ng-template>
          <ng-template pTemplate="body">
            <div class="grid">
              <div class="col-10">

                <div class="flex flex-column gap-1 text-block">
                  <div class="flex flex-row flex-wrap">
                    <div class="col-5"><span class="font-semibold"> Name </span></div>
                    <div class="col-7 book-mark-name">{{item.NameOfPersonWhoVisitedMeOVC }}</div>
                  </div>
                  <div class="flex flex-row flex-wrap">
                    <div class="col-5"><span class="font-semibold"> ID </span></div>
                    <div class="col-7 book-mark-id">{{item.IdOfPersonIVisitedOVC }}</div>
                  </div>
                  <div class="flex flex-row flex-wrap">
                    <div class="col-5"><span class="font-semibold"> Date </span></div>
                    <div class="col-7 book-mark-id">{{ item.DateIVisitedOVC | date }}</div>
                  </div>
                </div>

              </div>
              <div class="col-2">

                <div *ngIf="item?.showIconEngine">
                  <i class="pi pi-cog settings-icon" (click)="setShowIcon(item)"></i>
                </div>
                <div *ngIf="!item.showIconEngine" class="icon-set" @fadeInOut>
                  <div class="image-block"><img pTooltip="EXPRESS INTEREST" tooltipPosition="top"
                      src="../../../../assets/image/newicons/love-message-4669.png" class="brc-ito-3" alt=""
                      (click)="expressInterestPop($event, item)" /></div>
                  <div class="image-block"> <img pTooltip="SEND MESSAGE" tooltipPosition="top"
                      src="../../../../assets/image/newicons/envelope.png" class="brc-ito-2" alt=""
                      (click)="showSendDialogue(item)" /></div>
                  <div class="image-block"> <img pTooltip="VIEW PROFILE" tooltipPosition="top"
                      src="../../../../assets/image/newicons/id-card.png" class="brc-ito-1" alt=""
                      (click)="navigateToDetails(item)" /></div>
                  <div class="image-block"> <img pTooltip="DELETE" tooltipPosition="top"
                      src="../../../../assets/image/newicons/delete-10399.png" class="brc-ito-4" alt=""
                      (click)="deleteItem(item)" /></div>
                </div>

              </div>
            </div>



          </ng-template>
        </p-card>
      </div>

      <div style="margin-top: 10px; text-align: center">
        <pagination-controls (pageChange)="YourRecentVisitsOVCNumber = $event" id="YourRecentVisitsOVC">
        </pagination-controls>
      </div>
    </div>

    <div *ngIf="yourRecentVisitsOVC?.length === 0" class="no-value-text">
      You have not visited any profile yet
    </div>
  </p-fieldset>
</ng-containter>

<p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }">
  <large-image-window [tc]="imageToDisplay"></large-image-window>
</p-dialog>


<p-dialog header="Send Message" [(visible)]="smg.ShowSendMessage">
  <send-message #SendMessageComponent (hideSendMessageModel)="hideSendMessageWindow($event)"
    (OnSubmit)="eventPostSubmit($event)"></send-message>
</p-dialog>

<p-confirmPopup></p-confirmPopup>
<p-toast position="bottom-right"></p-toast>