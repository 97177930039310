<page>
  <ng-template>
    <super-floating-loader [isLoading]="showLoader"></super-floating-loader>

    <div class="text-block">
      <div [ngClass]="showSearchIcon ?  'super-title' : 'super-title-2'">
        <div class="flex justify-content-between flex-wrap">
          <div class="flex align-items-center">MESSAGE SENT</div>

          <div *ngIf="showSearchIcon">
            <i class="pi pi-search super-search-icon" (click)="showSearchIcon=!showSearchIcon"></i>
          </div>
          <div *ngIf="!showSearchIcon" @fadeInOut class="panel-search">

            <input type="text" pInputText placeholder="SEARCH" [(ngModel)]="searchText"
              (ngModelChange)="globalSearch($event)" />

          </div>
        </div>
      </div>
      <p-divider />

      <div class="panel panel-default">
        <div class="panel-header-container">
        </div>

        <div class="panel-body blue-panel-addon l-p-0 r-p-0 b-p-0 t-p-0 remove-padding">

          <div class="control-section standard-content">

            <div *ngIf="sortedData?.length === 0" class="no-value-text-ic">
              No messages in sent
            </div>


            <ng-container *ngIf="pagedRecords?.length>0">
              <ng-container [ngSwitch]="currentDeviceState">
                <ng-container *ngSwitchCase="'desktop-landscape'">
                  <ng-container *ngTemplateOutlet="desktopLandscape"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'mobile-portrait'">
                  <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="'tablet-portrait'">
                  <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                </ng-container>

              </ng-container>
            </ng-container>


          </div>
          <div class="grid-footer" *ngIf="pagedRecords?.length>0">
            <p-divider />
            <ng-container [ngSwitch]="currentDeviceState">
              <ng-container *ngSwitchCase="'desktop-landscape'">
                <ng-container *ngTemplateOutlet="desktopLandscapeFooter"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'mobile-portrait'">
                <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="'tablet-portrait'">
                <ng-container *ngTemplateOutlet="mobilePortraitFooter"></ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>




      <p-dialog [header]="vmsg.header" [(visible)]="showMessage" [style]="{ width: '25vw' }">
        <p>{{ vmsg.modelContent }}</p>
        <ng-template pTemplate="footer">
          <p-button icon="pi pi-check" (click)="showMessage = false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
      </p-dialog>
    </div>
    <p-toast position="bottom-right"></p-toast>

    <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
      <large-image-window [tc]="imageToDisplay"></large-image-window>
    </p-dialog>
    <p-confirmPopup></p-confirmPopup>


    <ng-template #cellTemplate let-value>
      <td class="text-block">
        @if (value !== null && value !== undefined) {
        @if (isDate(value)) {
        {{ value | date: "short" }}
        } @else {
        {{ value }}
        }
        } @else {
        Not available
        }
      </td>
    </ng-template>

    <ng-template #desktopLandscape>
      <p-table [value]="pagedRecords" [(selection)]="selectedMessageItems">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>Photo</th>
            <th class="text-block" pSortableColumn="UserToName"><p-sortIcon field="UserToName"></p-sortIcon>
              Name
            </th>
            <th class="text-block" pSortableColumn="userTo"><p-sortIcon field="userTo"></p-sortIcon> Id</th>
            <th class="text-block" pSortableColumn="height"><p-sortIcon field="height"></p-sortIcon> Height</th>
            <th class="text-block" pSortableColumn="age"><p-sortIcon field="age"></p-sortIcon> Age</th>
            <th class="text-block" pSortableColumn="christaintype"><p-sortIcon field="christaintype"></p-sortIcon>
              DENOMINATION</th>
            <th class="text-block" pSortableColumn="userExpressedDate"><p-sortIcon
                field="userExpressedDate"></p-sortIcon> Send On</th>
            <th class="text-block" pSortableColumn="messageStatus"><p-sortIcon field="messageStatus"></p-sortIcon>
              View Status</th>
            <th class="text-block" pSortableColumn="messageStatusChangeDate"><p-sortIcon
                field="messageStatusChangeDate"></p-sortIcon>
              Viewed On</th>
            <th class="text-block">Acceptance Status</th>
            <th class="text-block" pSortableColumn="acceptedOn"><p-sortIcon field="acceptedOn"></p-sortIcon>
              Acceptance On</th>
            <th class="text-block" pSortableColumn="UserFromName"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-grid>
          <tr>
            <td>
              <p-tableCheckbox [value]="grid"></p-tableCheckbox>
            </td>
            <td>
              <img [src]="grid.thumbNailImage" class="thumbnail-image" (click)="
                makeitbig(grid.candidateProfileImageTmb, grid.userToID)
              " />
            </td>
            <td class="text-block"> {{ grid.UserToName }} </td>
            <td class="text-block"> {{ grid.userTo }}</td>
            <td class="text-block"> {{ grid.height }}</td>
            <td class="text-block"> {{ grid.age }} Years</td>
            <ng-container *ngTemplateOutlet="cellTemplate; context: { $implicit: grid.christaintype }"></ng-container>
            <td class="text-block">{{ grid.userExpressedDate | date : "short" }}</td>
            <td class="text-block">{{ grid.messageStatus }}</td>
            <td class="text-block"> {{ grid.messageStatusChangeDate | date : "short"}}</td>
            <td class="text-block">
              @if(grid?.acceptanceStatus!=undefined && grid?.acceptanceStatus==='1')
              {
              Yes
              }
              @else if(grid?.acceptanceStatus===undefined ||grid?.acceptanceStatus===null ||
              grid?.acceptanceStatus!=='1')
              {
              No
              }
            </td>
            <td>
              <ng-container *ngTemplateOutlet="cellTemplate; context: { $implicit: grid.acceptedOn }"></ng-container>
            </td>
            <td>
              <div class="action-box-2 text-right">

                <div *ngIf="grid?.showIconEngine">
                  <i class="pi pi-cog edit-icon" tooltipPosition="left" pTooltip="ACTIONS"
                    (click)="setShowIcon(grid)"></i>
                </div>
                <div *ngIf="!grid.showIconEngine" class="icon-set" @fadeInOutFast>
                  <card-member-actions [userAction]="{
              fromId: grid.userFrom,
              userToId: grid.userTo,
              userToName: grid.UserToName.toUpperCase(),
              ShowSendMessage: false,
              sendMessageText: sendMessageText ?? '',
              Id: grid.Id,
              tableToDelete:'MessageSent'
            }" (triggerParentRefresh)="triggerParentRefresh($event)"></card-member-actions>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="caption">
          <div style="font-family: 'ComfortaaRegular' ;">
            Total Records: {{sortedData?.length}}
          </div>
        </ng-template>
      </p-table>
    </ng-template>
    <ng-template #mobilePortrait>
      <div *ngFor="let grid of pagedRecords" class="flex flex-column">
        <div class="flex flex-row">
          <div class="col-11">
            <img [src]="grid.thumbNailImage" class="thumbnail-image" (click)="
                makeitbig(grid.candidateProfileImageTmb, grid.userToID)
              " />
          </div>
          <div class="col-1">
            <div class="action-box flex justify-content-between md:justify-content-start">
              <div *ngIf="grid?.showIconEngine">
                <i class="pi pi-cog edit-icon" tooltipPosition="left" pTooltip="ACTIONS"
                  (click)="setShowIcon(grid)"></i>
              </div>
              <div *ngIf="!grid.showIconEngine" class="icon-set" @fadeInOutFast>
                <card-member-actions [userAction]="{
                  fromId: grid.userFrom,
                  userToId: grid.userTo,
                  userToName: grid.UserToName.toUpperCase(),
                  ShowSendMessage: false,
                  sendMessageText: sendMessageText ?? '',
                  Id: grid.Id,
                  tableToDelete:'MessageSent'
                }" (triggerParentRefresh)="triggerParentRefresh($event)"></card-member-actions>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Name</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.UserToName }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">ID</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.userTo }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Age</div>
          <div class="col-8 justify-content-center">
            <strong> : {{ grid.age }} Years</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Religion</div>
          <div class="col-8">
            <strong> : {{ grid.christaintype }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Edu Qualification</div>
          <div class="col-8">
            <strong> : {{ grid.Education }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Occupation</div>
          <div class="col-8">
            <strong> : {{ grid.Occupation }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Work Place</div>
          <div class="col-8">
            <strong> : {{ grid.Workplace }}</strong>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="col-4">Looking For</div>
          <div class="col-8">
            <strong>: {{ grid.PartnerExpectations }}</strong>
          </div>
        </div>
        <p-divider></p-divider>
      </div>
    </ng-template>

    <ng-template #mobilePortraitFooter>
      <div class="col-12 justify-content-center">
        <ng-container *ngIf="sortedData?.length > 0">
          <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
            [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true" [pageLinks]="5" [showPageLinks]="true"
            [showJumpToPageDropdown]="false" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
        </ng-container>
      </div>
    </ng-template>

    <ng-template #desktopLandscapeFooter>
      <div class="flex py-1">
        <div class="col-1 align-footer">
          <span class="label label-primary">Total Count {{ sortedData?.length }}</span>
        </div>

        <div class="col-9 justify-content-center">
          <ng-container *ngIf="sortedData?.length > 0">
            <p-paginator (onPageChange)="onPageChange($event)" [first]="first" [rows]="rows"
              [totalRecords]="totalRecords" [rowsPerPageOptions]="[10, 20, 30]" [showCurrentPageReport]="true"
              [pageLinks]="5" [showPageLinks]="true" [showJumpToPageDropdown]="false"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" />
          </ng-container>
        </div>

        <div class="col-2 text-right">
          <button pButton *ngIf="sortedData?.length > 0" (click)="openMultiDeleteModal()">
            Delete
          </button>
        </div>
      </div>
    </ng-template>


  </ng-template>
</page>